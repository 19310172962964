
import { ButtonContent } from "../global/boton/ButtonContent";
// import { GiRibbonMedal } from "react-icons/gi";
// import { AiOutlineTeam } from "react-icons/ai";
// import { RiPlantFill } from "react-icons/ri";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <>
      <div className="pic-wrapper svgHerosection1  w-full h-screen" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-[4] relative md:w-[40%] w-[90%] ml-[12%] h-full flex flex-col justify-center items-center md:pb-[80px] md:pt-[280px] pt-[400px] pb-[100px]">
          <div className="w-full flex flex-col md:justify-start justify-center items-center">
            <h1 className="text-white md:text-start text-center text-[35px] md:text-[70px] font-bold">
              {title}
            </h1>
            <p className="text-white md:text-start text-center">
              {texts}
            </p>
          </div>
          <div className="w-full flex md:justify-start justify-center py-5">
            <ButtonContent btnStyle={'three'} />
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
