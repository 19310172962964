import React, { useContext } from 'react'
import { GlobalDataContext } from '../../../context/context'
import { BiEnvelope, BiPhoneCall } from 'react-icons/bi'
import { BsClockHistory } from 'react-icons/bs'
import MenuList from './Menulist'
import { useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'




const HeaderTwo = ({ btnName }) => {

    const { rpdata } = useContext(GlobalDataContext)
    const [isOpen, setOpen] = useState(false);


    return (
        <header className='z-[10] w-full absolute top-0 bg2'>
            <div className='flex flex-col md:flex-row items-center md:items-stretch'>
                <div className='w-[80%] md:w-[20%] h-auto mt-1'>
                    <img
                        src={rpdata?.dbPrincipal?.logo}
                        alt='logo'
                        loading='lazy'
                        className='w-[90%] md:w-[80%] mx-auto'
                    />
                </div>
                <div className='w-[100%] md:w-[80%] flex md:flex-col flex-row items-center'>
                    <div className='w-full hidden md:h-[40%] bg-2 md:flex'>
                        <div className='hidden w-[20%] text-white lg:flex mx-auto items-center justify-center'>
                            <div className='w-[15%] self-center'>
                                <BiPhoneCall fontSize={20} />
                            </div>
                            <div>
                                <a
                                    href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`}
                                >
                                    <h5 className='text-[16px]'>
                                        {rpdata?.dbPrincipal?.phones?.[0]?.phone}
                                    </h5>
                                </a>
                            </div>
                        </div>
                        <div className='hidden w-[20%] text-white lg:flex mx-auto items-center justify-center'>
                            <div className='w-[15%] self-center'>
                                <BiEnvelope fontSize={20} />
                            </div>
                            <div>
                                <a
                                    href={`mailto:${rpdata?.dbPrincipal?.emails?.[0]?.email}`}
                                >
                                    <span className='text-[16px] font-bold'>
                                        {rpdata?.dbPrincipal?.emails?.[0]?.email}
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className='hidden w-[20%] text-white  lg:flex mx-auto justify-center items-center'>
                            <div className='w-[15%] self-center mr-3'>
                                <BsClockHistory fontSize={20} />
                            </div>
                            <div>
                                <p className='-mb-3'>
                                    {rpdata?.dbPrincipal?.workdays?.[0]?.day}
                                </p>
                                <h5 className='text-[16px]'>
                                    {rpdata?.dbPrincipal?.workHours?.[0]?.hour}
                                </h5>
                            </div>
                        </div>

                        <div className='w-[40%] flex justify-center items-center'>
                            <ul className="flex text-white">
                                {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                    return (
                                        <li key={index} className="mr-7">
                                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                <i className={`fab fa-${item.icon}`} aria-hidden="true" />
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>

                        </div>
                    </div>
                    <div className='w-full md:h-[40%] h-auto flex md:justify-center justify-around items-center md:items-end texte-end'>
                        <ul className="flex md:hidden text-white py-5 md:py-0">
                            {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                                return (
                                    <li key={index} className="mx-3">
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            <i className={`fab fa-${item.icon}`} aria-hidden="true" />
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                        <nav className='hidden md:flex w-auto '>
                            <MenuList classes={'text-white space-x-2'} />
                        </nav>
                    </div>
                    <div
                        className='md:hidden flex justify-center py-1 w-[50px] h-[40px] mr-10 bg-white rounded-sm'
                        onClick={() => setOpen(true)}
                    >
                        <GiHamburgerMenu fontSize={20} className="w-full h-full" />
                    </div>
                </div>
            </div>


            {isOpen ? (
                <div className="fixed z-50 top-0 right-0 left-0 bg-footer w-full h-screen overflow-y-auto pb-10">
                    <div className="flex justify-end w-[90%] mx-auto relative">
                        <button
                            className="font-bold text-[20px] mt-5 text-white"
                            onClick={() => setOpen(false)}
                        >
                            X
                        </button>
                    </div>
                    <div className="w-[60%] mx-auto pb-10">
                        <img
                            src={rpdata?.dbPrincipal?.logo}
                            alt="logo"
                            loading="lazy"
                            className="w-full"
                        />
                    </div>
                    <div className="w-4/5 mx-auto text-center">
                        <MenuList classes={'flex flex-col text-white space-x-2'} />
                    </div>


                </div>
            ) : null}

        </header>
    )
}

export default HeaderTwo